<template>
  <v-data-table
    :fixed-header="false"
    :height="undefined"
    v-if="companyId"
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
  >
    <template v-slot:top>
      <rs-table-title-bar
        class="mt-6"
        v-if="$refs.headSearch"
        title="Yönetilen Toplu Yaşam Alanları"
        icon="mdi-home-lightning-bolt-outline"
        @click:edit="handleEditClick"
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        :hide-edit="!can('edit-cluster')"
        :edit-enabled="selectedItems.length === 1"
        :search.sync="search.query"
      >
        <template slot="buttons:append">
          <v-btn
            color="pink"
            outlined
            small
            class="float-end ms-2"
            :disabled="selectedItems.length === 0"
            @click="handleAddProvidersClick"
          >
            Şirket Tedarikçilerini Ekle
          </v-btn>
        </template>
      </rs-table-title-bar>

      <rs-confirm
        ref="confirmProviders"
        :loading="isLoading"
        @confirmed="handleProviderSubmitClick"
        @cancelled="$refs.confirmProviders.hide()"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.append="{ headers }">
      <rs-table-foot-totals :headers="headers" :totals="footTotals" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.edit="{ item }">
      <router-link
        :to="{
          name: 'definitions.clusters.edit',
          params: { id: item.id },
        }"
        class="btn btn-icon btn-sm btn-clean"
      >
        <i class="menu-icon mdi mdi-pencil"></i>
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.name="{ item }">
      <router-link
        :to="{
          name: 'definitions.clusters.show',
          params: { id: item.id },
        }"
      >
        {{ item.name }}
      </router-link>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.is_active="{ item }">
      <rs-table-cell-boolean :value="item.is_active" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.bank_integration="{ item }">
      <rs-table-cell-boolean :value="item.bank_integration" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.sms_integration="{ item }">
      <rs-table-cell-boolean :value="item.sms_integration" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.cc_integration="{ item }">
      <rs-table-cell-boolean :value="item.cc_integration" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.legal_beginning_date="{ value }">
      <rs-table-cell-date :value="value" />
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.legal_ending_date="{ value }">
      <rs-table-cell-date :value="value" />
    </template>
  </v-data-table>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable, hasPermissions } from "@/view/mixins";

export default {
  mixins: [hasDataTable, hasPermissions],
  props: {
    companyId: {
      type: Number,
      required: true,
    },
    mode: {
      type: String,
      required: false,
      default: "admin",
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    companyId: {
      handler() {
        this.loadList();
      },
    },
  },
  data() {
    return {
      showForm: false,
      formBlockId: null,
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-cluster"),
        },
        {
          text: "TYA Adı",
          value: "name",
          searchable: "text",
        },
        {
          text: "BB Sayısı",
          value: "house_count",
          searchable: "number",
          sortable: true,
          align: "end",
        },
        {
          text: "Durum",
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
        {
          text: "Açılış Tarihi",
          value: "legal_beginning_date",
          searchable: "date",
        },
        {
          text: "Kapanış Tarihi",
          value: "legal_ending_date",
          searchable: "date",
        },
        {
          text: "Banka Entegrasyonu",
          value: "bank_integration",
          searchable: "yesNo",
          align: "center",
        },
        {
          text: "SMS Entegrasyonu",
          value: "sms_integration",
          searchable: "yesNo",
          align: "center",
        },
        {
          text: "Kredi Kartı Entegrasyonu",
          value: "cc_integration",
          searchable: "yesNo",
          align: "center",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.companyId) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.company_id = this.companyId;

      this.$api
        .query("clusters", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length === 1) {
        this.$router.push({
          name: "definitions.clusters.edit",
          params: { id: this.selectedItems[0].id },
        });
      }
    },
    handleAddProvidersClick() {
      if (this.selectedItems.length > 0) {
        this.$refs.confirmProviders.show(
          "Seçili toplu yaşam alanlarına şirket tedarikçilerini eklemek istediğinizden emin misiniz?"
        );
      }
    },
    handleProviderSubmitClick() {
      if (this.selectedItems.length === 0 || this.isLoading) {
        return false;
      }
      this.isLoading = true;

      const params = {
        cluster_ids: this.selectedItems.map((item) => item.id),
        company_id: this.companyId,
      };

      this.$api
        .post("company-providers/add-all-to-clusters", params)
        .then(() => {
          this.$toast.success("Kaydedildi");
          this.selectedItems = [];
          this.$refs.confirmProviders.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });

      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
