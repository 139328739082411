var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.companyId)?_c('v-data-table',_vm._b({attrs:{"fixed-header":false,"height":undefined,"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch)?_c('rs-table-title-bar',{staticClass:"mt-6",attrs:{"title":"Yönetilen Toplu Yaşam Alanları","icon":"mdi-home-lightning-bolt-outline","hide-edit":!_vm.can('edit-cluster'),"edit-enabled":_vm.selectedItems.length === 1,"search":_vm.search.query},on:{"click:edit":_vm.handleEditClick,"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)}}},[_c('template',{slot:"buttons:append"},[_c('v-btn',{staticClass:"float-end ms-2",attrs:{"color":"pink","outlined":"","small":"","disabled":_vm.selectedItems.length === 0},on:{"click":_vm.handleAddProvidersClick}},[_vm._v(" Şirket Tedarikçilerini Ekle ")])],1)],2):_vm._e(),_c('rs-confirm',{ref:"confirmProviders",attrs:{"loading":_vm.isLoading},on:{"confirmed":_vm.handleProviderSubmitClick,"cancelled":function($event){return _vm.$refs.confirmProviders.hide()}}})]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"body.append",fn:function({ headers }){return [_c('rs-table-foot-totals',{attrs:{"headers":headers,"totals":_vm.footTotals}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
        name: 'definitions.clusters.edit',
        params: { id: item.id },
      }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
        name: 'definitions.clusters.show',
        params: { id: item.id },
      }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.is_active",fn:function({ item }){return [_c('rs-table-cell-boolean',{attrs:{"value":item.is_active}})]}},{key:"item.bank_integration",fn:function({ item }){return [_c('rs-table-cell-boolean',{attrs:{"value":item.bank_integration}})]}},{key:"item.sms_integration",fn:function({ item }){return [_c('rs-table-cell-boolean',{attrs:{"value":item.sms_integration}})]}},{key:"item.cc_integration",fn:function({ item }){return [_c('rs-table-cell-boolean',{attrs:{"value":item.cc_integration}})]}},{key:"item.legal_beginning_date",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}},{key:"item.legal_ending_date",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}}],null,false,1747260707),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false)):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }